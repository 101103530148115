import CurrentIncidentView from '../components/CurrentIncidentView';
import ComponentView from '../components/ComponentView';
import ScheduledMaintenanceView from '../components/ScheduledMaintenanceView';
import IncidentLog from '../components/IncidentLog';

interface IIncidentPage {
  components: Array<any>;
  incidents: Array<any>;
  scheduledMaintenances: Array<any>;
}

const IncidentPage = ({
  components,
  incidents,
  scheduledMaintenances
}: IIncidentPage) => {
  return (
    <>
      <ComponentView components={components}></ComponentView>
      <CurrentIncidentView incidents={incidents}></CurrentIncidentView>
      <ScheduledMaintenanceView
        scheduledMaintenances={scheduledMaintenances}
      ></ScheduledMaintenanceView>
      <IncidentLog incidents={incidents}></IncidentLog>
    </>
  );
};

export default IncidentPage;
